<template>
  <g>
    <svg:style>
      .cls-1,
      .cls-2,
      .cls-3,
      .cls-4,
      .cls-5,
      .cls-6,
      .cls-7 {
        stroke: #aeaeae;
        stroke-width: 0.25px;
      }
      .cls-1,
      .cls-2,
      .cls-3 {
        stroke-miterlimit: 10;
      }
      .cls-1 {
        fill: url(#BG-gradient);
      }
      .cls-2 {
        fill: none;
      }
      .cls-3 {
        fill: url(#handle-gradient);
      }
      .cls-4,
      .cls-5,
      .cls-6,
      .cls-7 {
        stroke-linecap: round;
        stroke-linejoin: round;
      }
      .cls-4 {
        fill: url(#inox-gradient);
      }
      .cls-5 {
        fill: url(#inox-gradient-2);
      }
      .cls-6 {
        fill: url(#inox-gradient-3);
      }
      .cls-7 {
        fill: url(#glass-pattern);
      }
    </svg:style>


    <linearGradient id="handle-gradient"
                    x1="11.33"
                    y1="150.96"
                    x2="17.01"
                    y2="150.96"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth1 + 53.37"
                    :y1="doorTopHeight1 + 150.96"
                    :x2="doorLeftWidth1 + 88.71"
                    :y2="doorTopHeight1 + 150.96"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-2"
                    :x1="doorLeftWidth1 + 38.51"
                    :y1="doorTopHeight1 + 134.54"
                    :x2="doorLeftWidth1 + 43.44"
                    :y2="doorTopHeight1 + 134.54"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-3"
                    :x1="doorLeftWidth1 + 98.64"
                    :y1="doorTopHeight1 + 167.38"
                    :x2="doorLeftWidth1 + 103.57"
                    :y2="doorTopHeight1 + 167.38"
                    xlink:href="#handle-gradient"/>

    <g id="V30">
      <polyline id="inox"
                v-if="showInox"
                class="cls-4"
                :points="`${doorLeftWidth1 + 53.37} ${doorTopHeight1 + 246.47} ${doorLeftWidth1 + 88.71} ${doorTopHeight1 + 246.47} ${doorLeftWidth1 + 88.71} ${doorTopHeight1 + 55.46} ${doorLeftWidth1 + 53.37} ${doorTopHeight1 + 55.46} ${doorLeftWidth1 + 53.37} ${doorTopHeight1 + 246.47}`"/>
      <rect id="Inox-2"
            v-if="showInox"
            data-name="Inox"
            class="cls-5"
            :x="doorLeftWidth1 + 38.51"
            :y="inoxY1"
            width="4.93"
            :height="inoxH1"/>
      <rect id="inox-3"
            v-if="showInox"
            data-name="inox"
            class="cls-6"
            :x="doorLeftWidth1 + 98.64"
            :y="inoxY2"
            width="4.93"
            :height="inoxH2"/>
      <polyline id="glass"
            class="cls-7"
            :points="`${doorLeftWidth1 + 58.3} ${doorTopHeight1 + 241.54} ${doorLeftWidth1 + 83.78} ${doorTopHeight1 + 241.54} ${doorLeftWidth1 + 83.78} ${doorTopHeight1 + 60.39} ${doorLeftWidth1 + 58.3} ${doorTopHeight1 + 60.39} ${doorLeftWidth1 + 58.3} ${doorTopHeight1 + 241.54}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
    'laminationId',
  ],
  data() {
    return {
      inoxOffset: 22.18,
      leafOffset: 6.4,
      inoxHeight: 223.85,
    }
  },
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
    inoxH1() {
      return this.showBg ?
        this.inoxHeight + this.doorTopHeight1 - this.doorTopHeight:
        this.inoxHeight + this.doorTopHeight1 + this.inoxOffset - this.leafOffset - this.doorTopHeight
    },
    inoxH2() {
      return this.showBg ?
        this.inoxHeight + this.doorTopHeight1 - this.doorTopHeight + 4:
        this.inoxHeight + this.doorTopHeight1 + this.inoxOffset - this.leafOffset - this.doorTopHeight + 4
    },
    inoxY1() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset :
        this.doorTopHeight + this.leafOffset
    },
    inoxY2() {
      return this.showBg ?
        this.doorHeight - this.inoxOffset - this.inoxH2 + this.doorTopHeight:
        this.doorHeight - this.leafOffset - this.inoxH2 + this.doorTopHeight
    },
  }
}
</script>
